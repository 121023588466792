import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import AxiosInstance from "../../utils/axios";
export const subscriptionDetails = createAsyncThunk(
  "subscriptionDetails",
  async (page) => {
    try {
      const response = await AxiosInstance.get(`/subscription/details?limit=25&page=${page}`);
      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data.message;
      } else {
        throw error;
      }
    }
  }
);

const subscriptionDetailsSlice = createSlice({
  name: "subscriptionDetails",
  initialState: {
    isLoading: false,
    subscriptionData: null,
    isError: false,
    errorMessage: "",
  },
  extraReducers: {
    [subscriptionDetails.pending]: (state) => {
      state.isLoading = true;
    },
    [subscriptionDetails.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.subscriptionData = action.payload;
      state.isError = false;
      state.errorMessage = "";
    },
    [subscriptionDetails.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.error.message;
    },
  },
});

export default subscriptionDetailsSlice.reducer;
