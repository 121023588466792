import React, { useState, useEffect } from "react";
import HomePage from "../home";
import "./resetPassword.scss";
import { Form, Input, Modal } from "antd";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Cookies } from "react-cookie";
import useScrollToTop from "../customHook/useScrollToTop";
import { renderRTLClass } from "../../utils/commonUtils";

const ResetPasswordComponent = (props) => {
  const { data, handleReset, handleOnChange, formErrors, error } = props;
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(true);
  const navigate = useNavigate();
  const cookies = new Cookies();
  const lang = cookies.get("lang");
  const handleCancel = () => {
    setIsModalOpen(false);
    navigate(`/${lang}/`);
  };
  useEffect(() => {
    document.body.classList.toggle("no-scroll", isModalOpen);
    return () => document.body.classList.remove("no-scroll");
  }, [isModalOpen]);
  useScrollToTop();
  return (
    <div>
      <HomePage />
      <Modal
        centered
        open={isModalOpen}
        onCancel={handleCancel}
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
        maskClosable={false}
        className={renderRTLClass()}
      >
        <div className="login-modal-section">
          <div className="reset-modal-title">
            {t("RESET_PASSWORD_POPUP_TITLE")}
          </div>
          <div className="wrap-top-modal">
            <div className="body-modal">
              <Form>
                <div className="modal-login-form">
                  <Form.Item
                    label={t("LOGIN_EMAIL_LABEL")}
                    name="email"
                    value={data}
                    defaultValue={data}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                  >
                    <Input
                      placeholder="hello@mail.com"
                      defaultValue={data}
                      // type="email"
                      name="email"
                      value={data}
                      onChange={handleOnChange}
                      className={formErrors.email || error ? "errorInput" : ""}
                    />
                    {formErrors.email && (
                      <p style={{ margin: "0", color: "red" }}>
                        {formErrors.email}
                      </p>
                    )}
                    {error && (
                      <p style={{ margin: "0", color: "red" }}>{error}</p>
                    )}
                  </Form.Item>
                </div>

                <div className="submit-btn-modal">
                  <button onClick={handleReset} className="hl_cta_wrap">
                    {t("SET_NEW_PASSWORD")}
                  </button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ResetPasswordComponent;
