import GooglePayButton from '@google-pay/button-react';
import React from 'react'
import { gpay_merchantId, spreedly_merchantId } from '../../environment';

const GooglePay = (props) => {
    const { handleSubmit } = props;
    return (
        <GooglePayButton
            environment="PRODUCTION"
            buttonColor="white"
            buttonType="pay"
            paymentRequest={{
                apiVersion: 2,
                apiVersionMinor: 0,
                allowedPaymentMethods: [
                    {
                        type: "CARD",
                        parameters: {
                            allowedAuthMethods: ["PAN_ONLY", "CRYPTOGRAM_3DS"],
                            allowedCardNetworks: [
                                "AMEX",
                                "DISCOVER",
                                "INTERAC",
                                "JCB",
                                "MASTERCARD",
                                "VISA",
                            ],
                        },
                        tokenizationSpecification: {
                            type: "PAYMENT_GATEWAY",
                            parameters: {
                                gateway: "spreedly",
                                gatewayMerchantId: spreedly_merchantId,
                            },
                        },
                    },
                ],
                merchantInfo: {
                    merchantId:gpay_merchantId,
                },
                transactionInfo: {
                    totalPriceStatus: "FINAL",
                    totalPriceLabel: "Total",
                    totalPrice: "0.00",
                    currencyCode: "USD",
                    countryCode: "US",
                },
            }}
            onLoadPaymentData={async (paymentRequest) => {
                let token =
                    paymentRequest.paymentMethodData.tokenizationData.token;
                token = JSON.parse(token);
                await handleSubmit("google_pay", token);
            }}
        />
    )
}

export default GooglePay
