import React from "react";

import nextImg from "../../../assets/home/Icon-right-arrow.svg";
const NextArrow = (props) => {
  const { onClick, icon } = props;
  return (
    // eslint-disable-next-line
    <a className="slick-arrow slick-next outside" onClick={onClick}>
      {icon || <img src={nextImg} alt="" />}
    </a>
  );
};

export default NextArrow;
