import React, { useEffect, useState } from "react";
import { Cookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import AdminsComponent from "./admin";
import { connect } from "react-redux";
import { adminDeleteUser } from "../../redux/slice/adminDeleteUserSlice";
import { createNewAdmin } from "../../redux/slice/createNewAdminSlice";
import { adminUsers } from "../../redux/slice/adminUsersSlice";

const AdminUsersPage = (props) => {
  const {
    getAdminUsers,
    callAdminDeleteUser,
    callCreateNewAdmin,
  } = props;
  const navigate = useNavigate();
  const cookies = new Cookies();
  const role = cookies.get("role");
  const token = cookies.get("token");
  const lang = cookies.get("lang");
  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState({});
  const [loading, setLoading] = useState(false);

  const fetchData = async (page = 1) => {
    setLoading(true);
    const result = await getAdminUsers(page)
    if (result.type === "adminUsers/fulfilled") {
      setLoading(false);
      setData(result.payload.data);
      setPagination({
        total: result.payload.count,
        current: page,
        pageSize: 25,
      });
    };
  }
  useEffect(() => {
    if (role !== "admin") {
      if (token) {
        navigate(`/${lang}/dash`);
      } else {
        navigate(`/${lang}/`);
      }
    } else {
      fetchData()
    }
    // eslint-disable-next-line
  }, [role]);
  const handlePaginationChange = (page) => {
    fetchData(page);
  };
  return (
    <AdminsComponent
      callAdminDeleteUser={callAdminDeleteUser}
      getSubscriptionDetails={getAdminUsers}
      callCreateNewAdmin={callCreateNewAdmin}
      role={role}
      handlePaginationChange={handlePaginationChange}
      pagination={pagination}
      loading={loading}
      data={data}
    />
  );
};


const mapDispatchToProps = (dispatch) => {
  return {
    getAdminUsers: (...props) => dispatch(adminUsers(...props)),
    callAdminDeleteUser: (...props) => dispatch(adminDeleteUser(...props)),
    callCreateNewAdmin: (...props) => dispatch(createNewAdmin(...props)),
  };
};

export default connect(null, mapDispatchToProps)(AdminUsersPage);