import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
export const googleOauth2 = createAsyncThunk("googleOauth2", async (token) => {
    try {
        const response = await axios.get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${token}`, {
            headers: {
                Authorization: `Bearer ${token}`,
                Accept: 'application/json'
            }
        });
        return response.data;
    } catch (error) {
        if (error.response) {
            throw error.response.data.message;
        } else {
            throw error;
        }
    }
});

const googleOauth2Slice = createSlice({
    name: "googleOauth2",
    initialState: {
        isLoading: false,
        getIPIfyData: null,
        isError: false,
        errorMessage: "",
    },
    extraReducers: {
        [googleOauth2.pending]: (state) => {
            state.isLoading = true;
        },
        [googleOauth2.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.getIPIfyData = action.payload;
            state.isError = false;
            state.errorMessage = "";
        },
        [googleOauth2.rejected]: (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.errorMessage = action.error.message;
        },
    },
});

export default googleOauth2Slice.reducer;