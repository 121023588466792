import { Modal } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { renderRTLClass } from "../../../../utils/commonUtils";

const UnsubscribeModalComponent = ({
  openModal,
  onCancel,
  handleUnsubscribe,
  successPopup,
  unsubscribeResult,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <Modal
        open={openModal}
        onCancel={onCancel}
        centered
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
        className={renderRTLClass()}
      >
        <div className="login-modal-section">
          <div className="login-modal-title">
            <p>{t("MENU_UNSUBSCRIBE")}</p>
          </div>
          <div className="unsubscribe-modal-body">
            <div className="unsubscribe-text">
              {t("SETTING_UNSUBSCRIBE_TEXT")}
            </div>
            <div className="submit-btn-modal">
              <button
                onClick={handleUnsubscribe}
                //   disabled={loading}
              >
                {t("SETTING_UNSUBSCRIBE_SUBMIT_BUTTON")}
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        open={successPopup}
        centered
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
        className={renderRTLClass()}
      >
        <div className="login-modal-section">
          <div className="login-modal-title">
            <p>{t("MENU_UNSUBSCRIBE")}</p>
          </div>
          <div className="unsubscribe-modal-body">
            <div className="unsubscribe-text">
              {unsubscribeResult?.message}
              {/* {t("SETTING_UNSUBSCRIBE_SUCCESSFULL_MESSAGE")}  */}
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default UnsubscribeModalComponent;
