import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import AxiosInstance from "../../utils/axios";
export const productListSingle = createAsyncThunk("productListSingle", async () => {
    try {
        const response = await AxiosInstance.get(`/pricing/product-list/stripe`);
        return response.data;
    } catch (error) {
        if (error.response) {
            throw error.response.data.message;
        } else {
            throw error;
        }
    }
});

const productListSingleSlice = createSlice({
    name: "productListSingle",
    initialState: {
        isLoading: false,
        productListSingleData: null,
        isError: false,
        errorMessage: "",
    },
    extraReducers: {
        [productListSingle.pending]: (state) => {
            state.isLoading = true;
        },
        [productListSingle.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.productListSingleData = action.payload;
            state.isError = false;
            state.errorMessage = "";
        },
        [productListSingle.rejected]: (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.errorMessage = action.error.message;
        },
    },
});

export default productListSingleSlice.reducer;