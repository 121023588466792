import React from "react";

import prevImg from "../../../assets/home/Icon-left-arrow.svg";
const PrevArrow = (props) => {
  const { onClick, icon } = props;
  return (
    // eslint-disable-next-line
    <a className="slick-arrow slick-prev outside" onClick={onClick}>
      {icon || <img src={prevImg} alt="" />}
    </a>
  );
};

export default PrevArrow;
