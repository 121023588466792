import { Modal } from "antd";
import React from "react";
import paymentDeclineIcon from "../../../../assets/home/payment_decline_icon.svg";
import "./updatePaymentModal.scss";
import { useTranslation } from "react-i18next";

const UpdatePaymentModal = ({ setStep, handleNavigateUnsubscribe }) => {
  const { t } = useTranslation();
  const handleReActivePayment = () => {
    setStep(2);
  };
  return (
    <div className="upc__payment_update">
      <div className="upc__payment_icon">
        <img src={paymentDeclineIcon} alt="" />
      </div>
      <div className="upc__payment_content">
        <h4>{t("REACTIVATE_ACCOUNT")}</h4>
        <p>{t("REACTIVATE_ACCOUNT_DESC")}</p>
        <div className="upc__update_btn">
          <button onClick={handleReActivePayment}>
            {t("UPDATE_PAYMENT_METHOD")}
          </button>
        </div>
      </div>
      <div className="upc__cancel_info">
        <div className="upc__cancel_content">
          <p>{t("CANCEL_SUBSCRIPTION_DESC")}</p>
          <div className="upc__cancel_btn">
            <button onClick={handleNavigateUnsubscribe}>
              {t("CANCEL_SUBSCRIPTION")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdatePaymentModal;
