/* global gtag */
import React from "react";
import TraceComponent from "./trace";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import { connect } from "react-redux";
import { useState } from "react";
import { Cookies, useCookies } from "react-cookie";
import { settingBanIp } from "../../redux/slice/settingBanIpSlice";
import { checkSettingBanIp } from "../../redux/slice/checkSettingBanIpSlice";
import { iPQualityScore } from "../../redux/slice/iPQualityScoreSlice";
import platform from "platform";

const Trace = (props) => {
  const {
    callSettingBanIp,
    callCheckSettingBanIpSlice,
    getiPQualityScore,
    ipData,
  } = props;
  const cookies = new Cookies();
  const lang = cookies.get("lang");
  const navigate = useNavigate();
  const [searchparams] = useSearchParams();
  const searchNumber = Object.fromEntries([...searchparams]);
  const [ip, setIp] = useState();
  const [page, setPage] = useState("");
  const keys = Object.keys(searchNumber);
  const fx = searchparams.get("fx");
  const pageVersion = page === "Trace" ? "b" : "w";
  // eslint-disable-next-line no-unused-vars
  const [tokens, setTokens, removeToken] = useCookies(["param"]);
  // useEffect(() => {
  //   setParams(searchNumber);
  // }, [searchNumber]);
  useEffect(() => {
    if (page) {
      gtag("event", "page_view", {
        page_version: pageVersion,
        send_to: "G-K38QXZZP92",
      });
    }
    // eslint-disable-next-line
  }, [page]);
  useEffect(() => {
    if (keys.length > 0) {
      cookies.set("param", JSON.stringify(searchNumber), { path: "/" });
    } else {
      removeToken(["param"]);
    }
    // eslint-disable-next-line
  }, []);
  const banIp = cookies.get("banned");
  const token = cookies.get("token");

  useEffect(() => {
    if (ipData && ipData.ip) {
      setIp(ipData.ip);
    }
  }, [ipData]);
  const handleIpApiTest = async () => {
    const data = {
      ip_address: ip,
      user_agent: platform.ua,
    };
    const IpResult = await getiPQualityScore(data);
    if (
      IpResult.payload.data.data.ISP === "iCloud Private Relay" &&
      IpResult.payload.data.data.active_vpn
    ) {
      setPage("Trace");
    } else if (
      IpResult.payload.data.data.active_vpn ||
      IpResult.payload.data.data.is_crawler ||
      IpResult.payload.data.data.ISP.includes("Google") ||
      IpResult.payload.data.data.ISP.includes("google") ||
      (window.location.hostname === "tracelo.com" &&
        IpResult.payload.data.data.region.includes("Quebec"))
    ) {
      cookies.set("banned", "true");
      const data = {
        ip_address: ip,
        status: false,
      };
      callSettingBanIp(data);
      setPage("Home");
    } else {
      setPage("Trace");
    }
  };

  const handleGetIp = async () => {
    if (token) {
      navigate(`/${lang}/dash`);
    }
    if (ip) {
      const resData = {
        ip_address: ip,
      };
      const result = await callCheckSettingBanIpSlice(resData);

      if (keys.includes("off")) {
        setPage("Trace");
      } else if (keys.includes("clk")) {
        if (searchNumber.clk === "ae4h3") {
          if (banIp) {
            setPage("Home");
          } else if (!result?.payload?.data) {
            setPage("Home");
          } else {
            handleIpApiTest();
          }
        } else {
          setPage("Home");
        }
      } else {
        setPage("Home");
      }
    }
  };
  useEffect(() => {
    handleGetIp();
    // eslint-disable-next-line
  }, [ip]);
  return <TraceComponent page={page} utm_term={fx} />;
};
const mapStateToProps = (state) => {
  return {
    iPQualityScore: state.iPQualityScore,
    checkSettingBanIp: state.checkSettingBanIp,
    ipData: state.getIPIfy.getIPIfyData,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    callSettingBanIp: (data) => dispatch(settingBanIp(data)),
    callCheckSettingBanIpSlice: (data) => dispatch(checkSettingBanIp(data)),
    getiPQualityScore: (data) => dispatch(iPQualityScore(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Trace);
