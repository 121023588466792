import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import AxiosInstance from "../../utils/axios";
export const getLanguages = createAsyncThunk("adminUsers", async () => {
  try {
    const response = await AxiosInstance.get(`/translate/language_list`);
    return response.data;
  } catch (error) {
    if (error.response) {
      throw error.response.data.message;
    } else {
      throw error;
    }
  }
});

const getLanguagesSlice = createSlice({
  name: "getLanguages",
  initialState: {
    isLoading: false,
    subscriptionData: null,
    isError: false,
    errorMessage: "",
  },
  extraReducers: {
    [getLanguages.pending]: (state) => {
      state.isLoading = true;
    },
    [getLanguages.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.subscriptionData = action.payload;
      state.isError = false;
      state.errorMessage = "";
    },
    [getLanguages.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.error.message;
    },
  },
});

export default getLanguagesSlice.reducer;
