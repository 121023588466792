import React, { useEffect, useState } from "react";
import { Cookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import UsersComponent from "./users";
import { subscriptionDetails } from "../../redux/slice/subscriptionDetailsSlice";
import { connect } from "react-redux";


const Users = (props) => {
  const {
    getSubscriptionDetails,
  } = props;
  const navigate = useNavigate();
  const cookies = new Cookies();
  const role = cookies.get("role");
  const token = cookies.get("token");
  const lang = cookies.get("lang");
  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState({});
  const [loading, setLoading] = useState(false);

  const fetchData = async (page = 1) => {
    setLoading(true);
    const result = await getSubscriptionDetails(page)
    if (result.type === "subscriptionDetails/fulfilled") {
      setLoading(false);
      setData(result.payload.data);
      setPagination({
        total: result.payload.count,
        current: page,
        pageSize: 25,
      });
    };
  }
  useEffect(() => {
    if (role !== "admin") {
      if (token) {
        navigate(`/${lang}/dash`);
      } else {
        navigate(`/${lang}/`);
      }
    } else {
      fetchData()
    }
    // eslint-disable-next-line
  }, []);

  const handlePaginationChange = (page) => {
    fetchData(page);
  };
  return (
    <UsersComponent
      handlePaginationChange={handlePaginationChange}
      pagination={pagination}
      loading={loading}
      data={data}
    />
  );
};


const mapDispatchToProps = (dispatch) => {
  return {
    getSubscriptionDetails: (...props) => dispatch(subscriptionDetails(...props)),
  };
};

export default connect(null, mapDispatchToProps)(Users);