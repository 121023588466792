import React, { useState } from "react";
import "./tracking.scss";
import { useTranslation } from "react-i18next";
import howToUse from "../../assets/home/how-to-use.png";
import sectionOneIcon from "../../assets/home/sectionOneIcon.svg";
import sectionTwoIcon from "../../assets/home/sectionTwoIcon.png";
import sectionthreeIcon from "../../assets/home/sectionthreeIcon.png";
import sectionFourIcon from "../../assets/home/sectionFourIcon.svg";
import sectionFiveIcon from "../../assets/home/sectionFiveIcon.svg";
import servicesvg1 from "../../assets/home/Invisible-service.svg";
import servicesvg2 from "../../assets/home/skip-installation.svg";
import servicesvg3 from "../../assets/home/works-on-al-phones.svg";
import servicesvg4 from "../../assets/home/ios-android.svg";
import howUseOne from "../../assets/home/howUseOne.svg";
import howUseTwo from "../../assets/home/howUseTwo.svg";
import howUseThree from "../../assets/home/howUseThree.svg";
import trackBoxbg1 from "../../assets/track/trackBoxbg.jpg";
import trackBoxbg2 from "../../assets/track/trackBoxbg2.png";
import trackBoxbg3 from "../../assets/track/trackBoxbg3.png";
import computerMocup from "../../assets/track/computer_mocup.png";
import Slider from "react-slick";
import NextArrow from "./module/nextArrow";
import PrevArrow from "./module/prevArrow";
import Header from "../header";
import Footer from "../footer";
import plusIcon from "../../assets/home/plus.svg";
import minusIcon from "../../assets/home/minus.svg";
import PageBanner from "../pageBanner";
import { useAppContextInput } from "../../App";

const TrackingComponent = (props) => {
  const { t } = useTranslation();
  const [state, setState] = useState({ currentSlide: 1 });
  const [collapseTab, setCollapseTab] = useState(0);
  const handleScrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const carouselFullwidth = {
    dots: false,
    infinite: true,
    speed: 750,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,

    responsive: [
      {
        breakpoint: 1750,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },

      {
        breakpoint: 1366,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 680,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 380,
        settings: {
          slidesToShow: 1,
          infinite: true,
        },
      },
    ],
  };
  const sliderItem = `
      <div
        className="box group-box-1"
      >
        <img src=${trackBoxbg1} alt="" className="bgboximg" />

        <div className="how-work-contant">
          <div className="left-contant">
            <div className="how-work-box-title">
              <p>Kelly C.</p>
            </div>
            <div className="how-work-box-sub-title">
              <p>Melbourne, AU</p>
            </div>
            <div className="how-work-desc">
              <p>
                I’ve been looking for such a service for quite a while since I
                don’t know people who found Find My Phone helpful. Tracelo is
                very simple to operate. Besides, when I travel, I always know
                where <span> Read More...</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    `;
  const sliderArray = [sliderItem, sliderItem, sliderItem];
  const inputs = document.getElementsByClassName("form-control");
  if (inputs && inputs.length > 0) {
    inputs[0] && (inputs[0].disabled = true);
    inputs[1] && (inputs[1].disabled = true);
  }

  const { inputRef, mobileInputRef, plan } = useAppContextInput();

  return (
    <>
      {/* {!page && (
        <div className="overly-loader">
          <Spin size="large" />
        </div>
      )} */}
      <Header page={"track"} logoLoad={"noLoad"} />
      <PageBanner
        url={"track"}
        returnUrl={"track"}
        from={"none"}
        signUpUrl={"tracking"}
        inputRef={inputRef}
        mobileInputRef={mobileInputRef}
        plan={plan}
      />
      <React.Fragment>
        <div className="homePage-main-section">
          <div
            className="choose-tracelo-section"
            style={{ paddingBottom: "100px" }}
          >
            <div className="choose-tracelo-container">
              <div className="wrap-choose-tracelo">
                <div className="choose-tracelo-title">
                  <p>{t("WHY_CHOOSE_TITLE")}</p>
                </div>
                <div className="choose-tracelo-desc">
                  <p>{t("WHY_CHOOSE_DESCRIPTION")}</p>
                </div>
                <div className="choose-tracelo-under-section">
                  <div className="df-wrap-service">
                    <div className="choose-tracelo-box">
                      <div className="choose-tracelo-part">
                        <div className="choose-tracelo-tag">
                          <img
                            src={servicesvg1}
                            alt="“Invisible” service"
                            width={110}
                            height={110}
                          />
                        </div>
                        <div className="choose-tracelo-box-title">
                          <p>{t("WHY_CHOOSE_TAG_1_TITLE.B")}</p>
                        </div>
                        <div className="choose-tracelo-box-desc">
                          <p>{t("WHY_CHOOSE_TAG_1_TEXT.B")}</p>
                        </div>
                      </div>
                    </div>
                    <div className="choose-tracelo-box">
                      <div className="choose-tracelo-part">
                        <div className="choose-tracelo-tag">
                          <img
                            src={servicesvg2}
                            alt="Skip installation"
                            width={110}
                            height={110}
                          />
                        </div>
                        <div className="choose-tracelo-box-title">
                          <p>{t("WHY_CHOOSE_TAG_2_TITLE")}</p>
                        </div>
                        <div className="choose-tracelo-box-desc">
                          <p>{t("WHY_CHOOSE_TAG_2_TEXT.B")}</p>
                        </div>
                      </div>
                    </div>
                    <div className="choose-tracelo-box">
                      <div className="choose-tracelo-part">
                        <div className="choose-tracelo-tag">
                          <img
                            src={servicesvg3}
                            alt="Works on al phones and carriers"
                            width={110}
                            height={110}
                          />
                        </div>
                        <div className="choose-tracelo-box-title">
                          <p>{t("WHY_CHOOSE_TAG_3_TITLE")}</p>
                        </div>
                        <div className="choose-tracelo-box-desc">
                          <p>{t("WHY_CHOOSE_TAG_3_TEXT.B")}</p>
                        </div>
                      </div>
                    </div>
                    <div className="choose-tracelo-box">
                      <div className="choose-tracelo-part">
                        <div className="choose-tracelo-tag">
                          <img
                            src={servicesvg4}
                            alt="iOS and Android compatible"
                            width={110}
                            height={110}
                          />
                        </div>
                        <div className="choose-tracelo-box-title">
                          <p>{t("WHY_CHOOSE_TAG_4_TITLE")}</p>
                        </div>
                        <div className="choose-tracelo-box-desc">
                          <p>{t("WHY_CHOOSE_TAG_4_TEXT.B")}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="locate-btn">
                  <button className="hl_cta_wrap" onClick={handleScrollTop}>
                    {t("WHY_CHOOSE_LOCATE_BUTTON")}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="tracelo-users-section">
            <div className="tracelo-users-section-title">
              <p>{t("TRACK_HEAR_OUR_TRACELO_USER_TITLE")}</p>
            </div>
            <div className="slider">
              <Slider
                {...carouselFullwidth}
                afterChange={(currentSlide) => {
                  setState({ currentSlide: currentSlide + 1 });
                }}
              >
                <div className="slider-users">
                  <div className="box group-box-1">
                    <img src={trackBoxbg1} alt="" className="bgboximg" />

                    <div className="how-work-contant">
                      <div className="left-contant">
                        <div className="how-work-box-title">
                          <p>{t("TRACK_HEAR_TRACELO_USER_SLIDER_1_NAME")}</p>
                        </div>
                        <div className="how-work-box-sub-title">
                          <p>{t("TRACK_HEAR_TRACELO_USER_SLIDER_1_TEXT")}</p>
                        </div>
                        <div className="how-work-desc">
                          <p>
                            {t("TRACK_HEAR_TRACELO_USER_SLIDER_1_DESCRIPTION")}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="slider-users">
                  <div className="box group-box-1">
                    <img src={trackBoxbg2} alt="" className="bgboximg" />

                    <div className="how-work-contant">
                      <div className="left-contant">
                        <div className="how-work-box-title">
                          <p>{t("TRACK_HEAR_TRACELO_USER_SLIDER_2_NAME")}</p>
                        </div>
                        <div className="how-work-box-sub-title">
                          <p>{t("TRACK_HEAR_TRACELO_USER_SLIDER_2_TEXT")}</p>
                        </div>
                        <div className="how-work-desc">
                          <p>
                            {t("TRACK_HEAR_TRACELO_USER_SLIDER_2_DESCRIPTION")}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="slider-users">
                  <div className="box group-box-1">
                    <img src={trackBoxbg3} alt="" className="bgboximg" />

                    <div className="how-work-contant">
                      <div className="left-contant">
                        <div className="how-work-box-title">
                          <p>{t("TRACK_HEAR_TRACELO_USER_SLIDER_3_NAME")}</p>
                        </div>
                        <div className="how-work-box-sub-title">
                          <p>{t("TRACK_HEAR_TRACELO_USER_SLIDER_3_TEXT")}</p>
                        </div>
                        <div className="how-work-desc">
                          <p>
                            {t("TRACK_HEAR_TRACELO_USER_SLIDER_3_DESCRIPTION")}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Slider>
              <div className="slider-count">
                {state.currentSlide}
                {"  "}
                <span>/</span>
                {sliderArray.length}
              </div>
            </div>
          </div>
          <div className="why-tracelo-section">
            <div
              className="choose-tracelo-section"
              style={{
                paddingTop: "100px",
              }}
            >
              <div className="choose-tracelo-container">
                <div className="wrap-choose-tracelo">
                  <div className="choose-tracelo-title">
                    <p>{t("TRACK_WHY_TRACELO_TITLE")}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="why-tracelo">
              <div className="df-first-section">
                <div className="why-tracelo-top-contant">
                  <div className="banner-img">
                    <img src={computerMocup} alt="Banner" loading="lazy" />
                  </div>
                </div>
                <div className="left-contant">
                  <div className="why-tracelo-wrap">
                    <div className="df-why-tracelo">
                      <div className="sections">
                        <div className="icons">
                          <img src={sectionOneIcon} alt="" />
                        </div>
                        <div className="texts">
                          <div className="section-title">
                            <p>{t("TRACK_WHY_TRACELO_1_TITLE")}</p>
                          </div>
                          <div className="section-desc">
                            <p>{t("TRACK_WHY_TRACELO_1_TEXT")}</p>
                          </div>
                        </div>
                      </div>
                      <div className="sections">
                        <div className="icons">
                          <img src={sectionTwoIcon} alt="" />
                        </div>
                        <div className="texts">
                          <div className="section-title">
                            <p>{t("TRACK_WHY_TRACELO_2_TITLE")}</p>
                          </div>
                          <div className="section-desc">
                            <p>{t("TRACK_WHY_TRACELO_2_TEXT")}</p>
                          </div>
                        </div>
                      </div>
                      <div className="sections">
                        <div className="icons">
                          <img src={sectionthreeIcon} alt="" />
                        </div>
                        <div className="texts">
                          <div className="section-title">
                            <p>{t("TRACK_WHY_TRACELO_3_TITLE")}</p>
                          </div>
                          <div className="section-desc">
                            <p>{t("TRACK_WHY_TRACELO_3_TEXT")}</p>
                          </div>
                        </div>
                      </div>
                      <div className="sections">
                        <div className="icons">
                          <img src={sectionFourIcon} alt="" />
                        </div>
                        <div className="texts">
                          <div className="section-title">
                            <p>{t("TRACK_WHY_TRACELO_4_TITLE")}</p>
                          </div>
                          <div className="section-desc">
                            <p>{t("TRACK_WHY_TRACELO_4_TEXT")}</p>
                          </div>
                        </div>
                      </div>
                      <div className="sections">
                        <div className="icons">
                          <img src={sectionFiveIcon} alt="" />
                        </div>
                        <div className="texts">
                          <div className="section-title">
                            <p>{t("TRACK_WHY_TRACELO_5_TITLE")}</p>
                          </div>
                          <div className="section-desc">
                            <p>{t("TRACK_WHY_TRACELO_5_TEXT")}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="right-contant">
                  <div className="banner-img">
                    <img
                      src={computerMocup}
                      alt="Banner"
                      height={650}
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="how-to-use-section">
            <div className="how-to-use">
              <div className="how-to-use-title">
                <p>{t("TRACK_HOW_TO_USE_TITLE")}</p>
              </div>
              <div className="how-to-use-wrap">
                <div className="how-to-use-top">
                  <img src={howToUse} alt="Banner" />
                </div>
                <div className="how-to-use-df">
                  <div className="how-to-use-left">
                    <img src={howToUse} alt="Banner" />
                  </div>
                  <div className="how-to-use-right">
                    <div className="df-why-tracelo">
                      <div className="sections">
                        <div className="icons">
                          <img src={howUseOne} alt="" />
                        </div>
                        <div className="texts">
                          <div className="section-title">
                            <p>{t("TRACK_HOW_TO_USE_1_TEXT")}</p>
                          </div>
                        </div>
                      </div>
                      <div className="sections">
                        <div className="icons">
                          <img src={howUseTwo} alt="" />
                        </div>
                        <div className="texts">
                          <div className="section-title">
                            <p>{t("TRACK_HOW_TO_USE_2_TEXT")}</p>
                          </div>
                        </div>
                      </div>
                      <div className="sections">
                        <div className="icons">
                          <img src={howUseThree} alt="" />
                        </div>
                        <div className="texts">
                          <div className="section-title">
                            <p>{t("TRACK_HOW_TO_USE_3_TEXT")}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-grd-section">
          <div className="frequently-asked-question">
            <div className="question-title">
              <p>{t("FAQ_ASKED_QUESTION_TITLE")}</p>
              <span>{t("FAQ_ASKED_QUESTION_DESCRIPTION")}</span>
            </div>
            <div className="question-collapse">
              <div
                className="collapse-box"
                onClick={() => setCollapseTab(collapseTab === 1 ? 0 : 1)}
              >
                <div className="collapse-box-df-title">
                  <div className="collapse-box-title">
                    <p>{t("FAQ_ASKED_QUESTION_TAB_1_TITLE")}</p>
                  </div>
                  <div className="collapse-box-icon">
                    {collapseTab === 1 ? (
                      <div>
                        <img src={minusIcon} alt="Minus" />
                      </div>
                    ) : (
                      <div>
                        <img src={plusIcon} alt="Plus" />
                      </div>
                    )}
                  </div>
                </div>
                {collapseTab === 1 && (
                  <div className="open-tab-desc">
                    <p>{t("FAQ_ASKED_QUESTION_TAB_1_TEXT.B")}</p>
                  </div>
                )}
              </div>
              <div
                className="collapse-box"
                onClick={() => setCollapseTab(collapseTab === 2 ? 0 : 2)}
              >
                <div className="collapse-box-df-title">
                  <div className="collapse-box-title">
                    <p>{t("FAQ_ASKED_QUESTION_TAB_2_TITLE")}</p>
                  </div>
                  <div className="collapse-box-icon">
                    {collapseTab === 2 ? (
                      <div>
                        <img src={minusIcon} alt="Minus" />
                      </div>
                    ) : (
                      <div>
                        <img src={plusIcon} alt="Plus" />
                      </div>
                    )}
                  </div>
                </div>
                {collapseTab === 2 && (
                  <div className="open-tab-desc">
                    <p>{t("FAQ_ASKED_QUESTION_TAB_2_TEXT.B")}</p>
                  </div>
                )}
              </div>
              <div
                className="collapse-box"
                onClick={() => setCollapseTab(collapseTab === 3 ? 0 : 3)}
              >
                <div className="collapse-box-df-title">
                  <div className="collapse-box-title">
                    <p>{t("FAQ_ASKED_QUESTION_TAB_3_TITLE")}</p>
                  </div>
                  <div className="collapse-box-icon">
                    {collapseTab === 3 ? (
                      <div>
                        <img src={minusIcon} alt="Minus" />
                      </div>
                    ) : (
                      <div>
                        <img src={plusIcon} alt="Plus" />
                      </div>
                    )}
                  </div>
                </div>
                {collapseTab === 3 && (
                  <div className="open-tab-desc">
                    <p>{t("FAQ_ASKED_QUESTION_TAB_3_TEXT.B")}</p>
                  </div>
                )}
              </div>
              <div
                className="collapse-box"
                onClick={() => setCollapseTab(collapseTab === 4 ? 0 : 4)}
              >
                <div className="collapse-box-df-title">
                  <div className="collapse-box-title">
                    <p>{t("FAQ_ASKED_QUESTION_TAB_4_TITLE")}</p>
                  </div>
                  <div className="collapse-box-icon">
                    {collapseTab === 4 ? (
                      <div>
                        <img src={minusIcon} alt="Minus" />
                      </div>
                    ) : (
                      <div>
                        <img src={plusIcon} alt="Plus" />
                      </div>
                    )}
                  </div>
                </div>
                {collapseTab === 4 && (
                  <div className="open-tab-desc">
                    <p>{t("FAQ_ASKED_QUESTION_TAB_4_TEXT")}</p>
                  </div>
                )}
              </div>
              <div
                className="collapse-box"
                onClick={() => setCollapseTab(collapseTab === 5 ? 0 : 5)}
              >
                <div className="collapse-box-df-title">
                  <div className="collapse-box-title">
                    <p>{t("FAQ_ASKED_QUESTION_TAB_5_TITLE")}</p>
                  </div>
                  <div className="collapse-box-icon">
                    {collapseTab === 5 ? (
                      <div>
                        <img src={minusIcon} alt="Minus" />
                      </div>
                    ) : (
                      <div>
                        <img src={plusIcon} alt="Plus" />
                      </div>
                    )}
                  </div>
                </div>
                {collapseTab === 5 && (
                  <div className="open-tab-desc">
                    <p>{t("FAQ_ASKED_QUESTION_TAB_5_TEXT.B")}</p>
                  </div>
                )}
              </div>
              <div
                className="collapse-box"
                onClick={() => setCollapseTab(collapseTab === 6 ? 0 : 6)}
              >
                <div className="collapse-box-df-title">
                  <div className="collapse-box-title">
                    <p>{t("FAQ_ASKED_QUESTION_TAB_6_TITLE")}</p>
                  </div>
                  <div className="collapse-box-icon">
                    {collapseTab === 6 ? (
                      <div>
                        <img src={minusIcon} alt="Minus" />
                      </div>
                    ) : (
                      <div>
                        <img src={plusIcon} alt="Plus" />
                      </div>
                    )}
                  </div>
                </div>
                {collapseTab === 6 && (
                  <div className="open-tab-desc">
                    <p>{t("FAQ_ASKED_QUESTION_TAB_6_TEXT")}</p>
                  </div>
                )}
              </div>

              <div
                className="collapse-box"
                onClick={() => setCollapseTab(collapseTab === 11 ? 0 : 11)}
              >
                <div className="collapse-box-df-title">
                  <div className="collapse-box-title">
                    <p>{t("FAQ_ASKED_QUESTION_TAB_11_TITLE")}</p>
                  </div>
                  <div className="collapse-box-icon">
                    {collapseTab === 11 ? (
                      <div>
                        <img src={minusIcon} alt="Minus" />
                      </div>
                    ) : (
                      <div>
                        <img src={plusIcon} alt="Plus" />
                      </div>
                    )}
                  </div>
                </div>
                {collapseTab === 11 && (
                  <div className="open-tab-desc">
                    <p>{t("FAQ_ASKED_QUESTION_TAB_11_TEXT")}</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
      <Footer page={"track"} backURL={"tracking"} />
    </>
  );
};

export default TrackingComponent;
