import React from "react";
import FooterComponent from "./footer";

const Footer = (props) => {
  const { page, signOption, footerSignUpKey } = props;
  return (
    <FooterComponent
      page={page}
      signOption={signOption}
      footerSignUpKey={footerSignUpKey}
    />
  );
};

export default Footer;
