import React from "react";
import CurrencyComponent from "./currency";
import { connect } from "react-redux";
import { getCurrency } from "../../redux/slice/getCurrencySlice";
import { useEffect } from "react";
import { addEditCurrency } from "../../redux/slice/addEditCurrencySlice";
import { deleteCurrency } from "../../redux/slice/deleteCurrencySlice";

const Currency = (props) => {
  const { getCurrency, getCurrencyData, addEditCurrency, deleteCurrency } =
    props;
  useEffect(() => {
    getCurrency();
    // eslint-disable-next-line
  }, []);

  return (
    <CurrencyComponent
      getCurrencyData={getCurrencyData.getCurrencyData}
      addEditCurrency={addEditCurrency}
      getCurrency={getCurrency}
      deleteCurrency={deleteCurrency}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    getCurrencyData: state.getCurrencyData,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getCurrency: () => dispatch(getCurrency()),
    addEditCurrency: (data) => dispatch(addEditCurrency(data)),
    deleteCurrency: (data) => dispatch(deleteCurrency(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Currency);
