import React from "react";
import "./users.scss";
import { Link } from "react-router-dom";
import mainLogo from "../../assets/header/Dark-Logo.svg";
import { useTranslation } from "react-i18next";
import { AiFillSetting } from "react-icons/ai";
import { RiAdminFill } from "react-icons/ri";
import { Dropdown, Pagination, Space, Table } from "antd";
import Timestamp from "react-timestamp";
import locateSvg from "../../assets/header/settings.svg";
import { Cookies } from "react-cookie";
import { MailOutlined } from "@ant-design/icons";
import { HiMenu } from "react-icons/hi";
import { TbLogout } from "react-icons/tb";
import { FaUsers } from "react-icons/fa";
import { MdPriceChange } from "react-icons/md";
import { useAppContextInput } from "../../App";
const UsersComponent = (props) => {
  const { handlePaginationChange, pagination, loading, data } = props;
  const { t } = useTranslation();
  const columns = [
    {
      title: "User Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Plan",
      dataIndex: "plan",
      key: "plan",
    },
    {
      title: "Currency",
      dataIndex: "currency",
      key: "currency",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Start Date",
      dataIndex: "startDate",
      key: "startDate",
      render: (text) => <Timestamp date={text} />,
    },

    {
      title: "End Date",
      dataIndex: "endDate",
      key: "endDate",
      render: (text) => <Timestamp date={text} />,
    },
  ];
  const tabled =
    Array.isArray(data) &&
    data?.map((item, index) => ({
      key: (index + 1).toString(),
      _id: item._id,
      email: item.email,
      currency: item.currency,
      amount: item.amount || item?.subscription?.amount,
      plan: item.plan,
      startDate: item.trial_start_date || item.subscription?.start_date,
      endDate: item.trial_end_date || item.subscription?.end_date,
    }));
  const cookies = new Cookies();
  const lang = cookies.get("lang");
  const role = cookies.get("role");
  // hum-burger-menu
  const { handleLogout } = useAppContextInput();
  const items = [
    {
      label: (
        <Link to={`/${lang}/dash`}>
          <img src={locateSvg} alt="" width={20} height={20} />
          <span>{t("DASHBOARD")}</span>
        </Link>
      ),
      key: "0",
    },
    {
      label: (
        <Link to={`/${lang}/settings`}>
          <AiFillSetting />
          <span>{t("FOOTER_ACCOUNT")}</span>
        </Link>
      ),
      key: "1",
    },
    role === "admin" && {
      label: (
        <Link to={`/${lang}/email-test`}>
          <MailOutlined />
          <span>{t("TEST_EMAIL ")}</span>
        </Link>
      ),
      key: "2",
    },
    role === "admin" && {
      label: (
        <Link to={`/${lang}/currency`}>
          <MdPriceChange />
          <span>{t("CURRENCIES ")}</span>
        </Link>
      ),
      key: "3",
    },
    role === "admin" && {
      label: (
        <Link to={`/${lang}/admin`}>
          <RiAdminFill />
          <span>{t("ADMINS")}</span>
        </Link>
      ),
      key: "4",
    },
    role === "admin" && {
      label: (
        <Link to={`/${lang}/users`} className="active">
          <FaUsers />
          <span>{t("DASHBOARD_HEADER_USER")}</span>
        </Link>
      ),
      key: "5",
    },
    {
      label: (
        <Link to={`#`} onClick={handleLogout}>
          <TbLogout />
          <span>{t("LOGOUT")}</span>
        </Link>
      ),
      key: "6",
    },
  ];
  return (
    <div className="dashnoard-main-section">
      <div className="container">
        <div className="dashboard-header">
          <div className="main-logo-img">
            <Link to={`/${lang}/dash`}>
              <img src={mainLogo} alt="" />
            </Link>
          </div>
          <div className="hum-burger-menu">
            <Dropdown
              menu={{ items }}
              trigger={["click"]}
              placement="bottomRight"
              overlayClassName="dashboard-header-menu-mobile"
            >
              <span onClick={(e) => e.preventDefault()}>
                <Space>
                  <HiMenu />
                </Space>
              </span>
            </Dropdown>
          </div>
        </div>
        <div className="users-main">
          <div className="users-table">
            <Table
              columns={columns}
              dataSource={tabled}
              pagination={false}
              loading={loading}
            />
          </div>
        </div>
        {pagination.total > 25 && (
          <Pagination
            {...pagination}
            showSizeChanger={false}
            onChange={handlePaginationChange}
            className="user-pagination"
          />
        )}
      </div>
    </div>
  );
};

export default UsersComponent;
