import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import AxiosInstance from "../../utils/axios";
export const emailSender = createAsyncThunk("emailSender", async (data) => {
  try {
    const response = await AxiosInstance.post(
      `/translate/send_test_email`,
      data,
      {
        headers: {
          "X-Site": data.headers,
        },
      }
    );
    return response.data;
  } catch (error) {
    if (error.response) {
      throw error.response.data.message;
    } else {
      throw error;
    }
  }
});

const emailSenderSlice = createSlice({
  name: "emailSender",
  initialState: {
    isLoading: false,
    emailSenderData: null,
    isError: false,
    errorMessage: "",
  },
  extraReducers: {
    [emailSender.pending]: (state) => {
      state.isLoading = true;
    },
    [emailSender.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.emailSender = action.payload;
      state.isError = false;
      state.errorMessage = "";
    },
    [emailSender.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.error.message;
    },
  },
});

export default emailSenderSlice.reducer;
