import React from "react";
import "./location.scss";
import mainLogo from "../../assets/header/Logo.svg";
import loadingGif from "../../assets/location.gif";
import { useTranslation } from "react-i18next";

const LocationComponent = (props) => {
  const { location, address, loading, locationKey, keyMessage } = props;
  const { t } = useTranslation();
  return locationKey ? (
    <div>
      {loading ? (
        <img src={loadingGif} className="loading-spin-image" alt="loading..." />
      ) : (
        <div className="location-page">
          <div className="back-grad-title">
            <div className="pricing-main-title">
              <div className="location-main-section">
                <div className="location-main-logo">
                  <img src={mainLogo} alt="" />
                </div>
                <div className="location-your-address">
                  {keyMessage ? (
                    <p>{keyMessage}</p>
                  ) : location ? (
                    <p>{address}</p>
                  ) : (
                    <p>{t("LOCATION_DENIED_OR_UNAVAILABLE")}</p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  ) : (
    <div className="location-page">
      <div className="back-grad-title">
        <div className="pricing-main-title">
          <div className="location-main-section">
            <div className="location-main-logo">
              <img src={mainLogo} alt="" />
            </div>
            <div className="location-your-address">
              {keyMessage ? (
                <p>{keyMessage}</p>
              ) : location ? (
                <p>{address}</p>
              ) : (
                <p>{t("LOCATION_DENIED_OR_UNAVAILABLE")}</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LocationComponent;
