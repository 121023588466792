/* global gtag */
import { useStripe } from "@stripe/react-stripe-js";
import React from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Notify from "../../common/Notify/notify";
import { Cookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import { Form, Input } from "antd";
import countryList from "react-select-country-list";
import { MdOutlineCreditCard } from "react-icons/md";
import applePayIcon from "../../../assets/checkout/applePayIcon.png";
import googlePayIcon from "../../../assets/checkout/gpayIcon.png";
import paymentAuthIcon from "../../../assets/checkout/paymentAuthor.png";
import RightIcon from "../../../assets/checkout/rightIcon.png";
import ApplePayPage from "../../applePay"; 
import cardIcons from "../../../assets/checkout/cardIcons.png";
import GooglePay from "../../googlePay";
import { spreedly_merchantId, applePay_merchantId } from "../../../environment";
import { useSelector } from "react-redux";

const CheckoutComponent = (props) => {
  const {
    isLoading,
    setIsLoading,
    TrialCurr,
    subscriptionProductPrice,
    callsubscriptionCreate,
    subscription,
    paymentPrice,
    page,
    callgclidData,
    gclid,
    callGetPaymentMethod,
    callNewPaymentIntent,
    useId,
    ipData,
    senderNumber,
    senderNumberCountry,
    callSettingBanIp,
    sendDetailsData,
    paramas,
  } = props;
  const { t } = useTranslation();
  const stripe = useStripe();
  const [message, setMessage] = useState({
    applePay: "",
    googlePay: "",
    creditCard: "",
  });
  const cookie = new Cookies();
  const lang = cookie.get("lang");
  const cur = cookie.get("currency");
  const navigate = useNavigate();
  const country = cookie.get("country_name");
  const country_code = cookie.get("country_code");
  const postalCode = cookie.get("postalCode");
  const pageVersion = page === "track" || page === "trace" ? "b" : "w";
  const [checkoutCountry, setCountry] = useState("CA");
  const [label, setLabel] = useState("Postal code");
  const [zipCode, setZipCode] = useState("");
  const [ip, setIp] = useState("");
  const [isValidZip, setIsValidForZip] = useState(false);
  const [method, setMethod] = useState("card");
  const [isGooglePayAvailable, setIsGooglePayAvailable] = useState(false);
  const [isApplePayAvailable, setIsApplePayAvailable] = useState(false);
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [isValidCVV, setIsValidCVV] = useState(false);
  const [validateError, setValidateError] = useState({
    creditCardNumber: "",
    expiryDate: "",
    cvv: "",
    zipCode: "",
  });
  const [cardValidateError, setCardValidateError] = useState({
    numberError: "",
    yearError: "",
  });
  const [expiryDate, setExpiryDate] = useState("");
  const Spreedly = window.Spreedly;
  const countryOptions = countryList().getData();
  const renderRTLClass = ["ar", "he"].includes(
    window.location.pathname.split("/")[1]
  );

  const emailState = useSelector(
    (state) => state.registerData?.registerData?.data?.email
  );
  const user_id = useSelector(
    (state) => state.registerData?.registerData?.data?.user_id
  );
  const intentLoading = useSelector(
    (state) => state.newPaymentIntent?.isLoading
  );

  useEffect(() => {
    if (sendDetailsData) {
      setCountry(sendDetailsData.data.country);
      setCity(sendDetailsData.data.city);
      setState(sendDetailsData.data.region);
    }
  }, [sendDetailsData]);

  useEffect(() => {
    if (ipData && ipData.ip) {
      setIp(ipData.ip);
    }
  }, [ipData]);

  useEffect(() => {
    const ValiedForzip = ["GB", "US", "CA"];
    const isValidForzip = ValiedForzip.includes(checkoutCountry);
    isValidForzip ? setIsValidForZip(true) : setIsValidForZip(false);
    if (checkoutCountry === "PH" || checkoutCountry === "US") {
      setLabel(t("ZIP_CODE"));
    } else {
      setLabel(t("POSTAL_CODE"));
    }
  }, [checkoutCountry, t]);

  // // // google pay availability check
  useEffect(() => {
    const baseRequest = {
      apiVersion: 2,
      apiVersionMinor: 0,
      existingPaymentMethodRequired: true,
    };
    const allowedCardNetworks = [
      "AMEX",
      "DISCOVER",
      "INTERAC",
      "JCB",
      "MASTERCARD",
      "VISA",
    ];
    const allowedCardAuthMethods = ["PAN_ONLY", "CRYPTOGRAM_3DS"];
    const baseCardPaymentMethod = {
      type: "CARD",
      parameters: {
        allowedAuthMethods: allowedCardAuthMethods,
        allowedCardNetworks: allowedCardNetworks,
      },
    };
    const paymentsClient = new window.google.payments.api.PaymentsClient({
      environment: "PRODUCTION",
    });

    const isReadyToPayRequest = Object.assign({}, baseRequest);
    isReadyToPayRequest.allowedPaymentMethods = [baseCardPaymentMethod];
    paymentsClient
      .isReadyToPay(isReadyToPayRequest)
      .then(function (response) {
        if (response) {
          if (response.result && response.paymentMethodPresent) {
            setIsGooglePayAvailable(true);
          } else {
            setIsGooglePayAvailable(false);
          }
        }
      })
      .catch(function (err) {
        console.error(err);
      });
  }, []);

  // // Apple pay availability check
  useEffect(() => {
    if (window.ApplePaySession) {
      var merchantIdentifier = applePay_merchantId;
      var promise =
        window.ApplePaySession.canMakePaymentsWithActiveCard(
          merchantIdentifier
        );
      promise.then(function (canMakePayments) {
        if (canMakePayments) {
          setIsApplePayAvailable(true);
        } else {
          setIsApplePayAvailable(false);
        }
      });
    }
  }, []);

  useEffect(() => {
    if (method === "card") {
      setIsLoading(true);
      Spreedly.init(spreedly_merchantId);
      Spreedly.on("ready", (frame) => {
        setIsLoading(false);
        Spreedly.setFieldType("number", "text");
        Spreedly.setFieldType("cvv", "text");
        Spreedly.setPlaceholder("number", "1212 1212 1212 1212");
        Spreedly.setPlaceholder("cvv", "CVC");
        Spreedly.setNumberFormat("prettyFormat");
        Spreedly.setStyle(
          "placeholder",
          "color: rgba(0, 0, 0, 0.25); font-size: 14px; font-weight: 400; font-family: Arial, Helvetica, Sans-Serif !important;"
        );
        Spreedly.setStyle(
          "number",
          `width: calc(100% - 17px); borderRadius: 7px; border: 1px solid rgb(230, 230, 230); padding: 0.8em 0.5em; font-size: 14px; color: rgba(0, 0, 0, 0.88); fontFamily: Arial, Helvetica, sans-serif; backgroundColor: rgb(255, 255, 255); outline:none;${
            renderRTLClass ? " text-align: right;" : ""
          }`
        );
        Spreedly.setStyle(  
          "cvv",
          `width: calc(100% - 17px); border-radius: 5px; border: 1px solid rgb(230, 230, 230); padding: 0.8em 0.5em; font-size: 14px; background: white; position: absolute; left: 0px; color: rgba(0, 0, 0, 0.88); font-family: Arial, Helvetica, sans-serif;${
            renderRTLClass ? "text-align: right" : ""
          }`
        );
      });
    }
    // eslint-disable-next-line
  }, [method]);
  useEffect(() => {
    let cvvLength = 0;
    Spreedly.on("fieldEvent", (name, event, activeElement, inputData) => {
      const errorBorder = "1px solid red";
      if (event === "input") {
        if (inputData.activeElement === "number") {
          if (inputData["validNumber"]) {
            setCardValidateError((prev) => ({
              ...prev,
              numberError: null,
            }));
            Spreedly.setStyle(
              "number",
              "background-color: #FFFFFF; border:1px solid #E6E6E6"
            );
          } else {
            if (inputData.numberLength >= 16 || inputData.numberLength === 0) {
              setCardValidateError((prev) => ({
                ...prev,
                numberError:
                  inputData.numberLength === 0
                    ? t("YOUR_CARD_INCOMPLETE")
                    : t("YOUR_CARD_INVALID"),
              }));
              Spreedly.setStyle("number", `border: ${errorBorder};`);
            } else {
              setCardValidateError((prev) => ({
                ...prev,
                numberError: null,
              }));
              Spreedly.setStyle(
                "number",
                "background-color: #FFFFFF; border:1px solid #E6E6E6"
              );
            }
          }
        }
        cvvLength = inputData.cvvLength;
        if (inputData.activeElement === "cvv" && isFormSubmitted) {
          if (inputData.cvvLength > 2) {
            setIsValidCVV(true);
            setValidateError((prev) => ({
              ...prev,
              cvv: null,
            }));
            Spreedly.setStyle("cvv", `border: 1px solid #E6E6E6;`);
          } else {
            setIsValidCVV(false);
            setValidateError((prev) => ({
              ...prev,
              cvv: t("CVC_INCOMPLETE"),
            }));
            Spreedly.setStyle("cvv", `border: ${errorBorder};`);
          }
        }
        if (
          inputData.activeElement === "cvv" &&
          !isFormSubmitted &&
          inputData.cvvLength > 2
        ) {
          setIsValidCVV(true);
          setValidateError((prev) => ({
            ...prev,
            cvv: null,
          }));
          Spreedly.setStyle("cvv", `border: 1px solid #E6E6E6;`);
        }
      }
      if (
        event === "blur" &&
        name === "cvv" &&
        cvvLength < 3 &&
        cvvLength !== 0
      ) {
        setIsValidCVV(false);
        setValidateError((prev) => ({
          ...prev,
          cvv: t("CVC_INCOMPLETE"),
        }));
        Spreedly.setStyle("cvv", `border: ${errorBorder};`);
      }
    });
    // eslint-disable-next-line
  }, [method, isFormSubmitted]);

  const submitPaymentForm = (event) => {
    event.preventDefault();
    setIsLoading(true);
    const validationError = validateFields();
    if (validationError) {
      setIsLoading(false);
      return;
    }
    setIsFormSubmitted(true);
    if (!isFormSubmitted) {
      Spreedly.on("paymentMethod", (token) => {
        const tokenField = document.getElementById("payment_method_token");
        tokenField.setAttribute("value", token);
        token && setMessage({ ...message, creditCard: null });
        setCardValidateError({
          numberError: "",
          yearError: "",
        });
        handleSubmit("card", token);
      });
    }
    Spreedly.on("errors", (errors) => {
      const errorBorder = "1px solid red";
      setCardValidateError({
        numberError: "",
        yearError: "",
      });
      for (let i = 0; i < errors.length; i++) {
        const error = errors[i];
        if (error["attribute"]) {
          const masterFormElement = document.getElementById(error["attribute"]);
          setCardValidateError((prev) => ({
            ...prev,
            [`${error["attribute"]}Error`]: error["message"],
          }));
          if (masterFormElement) {
            masterFormElement.style.border = errorBorder;
          } else {
            Spreedly.setStyle(error["attribute"], `border: ${errorBorder};`);
          }
        }
      }
      setIsLoading(false);
    });

    const normalBorder = "1px solid #E6E6E6";
    Spreedly.setParam("allow_blank_name", true);
    Spreedly.setParam("email", emailState);
    Spreedly.setParam("month", expiryDate?.split("/")[0]?.trim());
    Spreedly.setParam("year", "20" + expiryDate?.split("/")[1]?.trim());
    Spreedly.setStyle("number", `border: ${normalBorder};`);
    Spreedly.setStyle("cvv", `border: ${normalBorder};`);
    Spreedly.setStyle(
      "number",
      "background-color: #FFFFFF; border:1px solid #E6E6E6"
    );
    if (!isValidCVV || validationError) {
      !isValidCVV &&
        Spreedly.setStyle(
          "cvv",
          "background-color: #FFFFFF;border:1px solid red"
        );
      setIsLoading(false); // Stop loading if CVV is invalid
      return;
    }
    Spreedly.tokenizeCreditCard();
  };
  const handleChangeZip = (e) => {
    e.target.value = e.target.value.toUpperCase();
    setZipCode(e.target.value);
    setValidateError((prev) => ({
      ...prev,
      zipCode: "",
    }));
  };
  const isValidExpiryDate = (expiryDate) => {
    const currentMonth = new Date().getUTCMonth() + 1; // Months are 0-indexed
    const currentYear = new Date().getUTCFullYear() % 100; // Get last two digits of the year

    if (expiryDate.length === 7 && expiryDate.includes("/")) {
      const enteredMonth = parseInt(expiryDate.substring(0, 2), 10);
      const enteredYear = parseInt(expiryDate.substring(5), 10);

      if (
        (enteredYear > currentYear ||
          (enteredYear === currentYear && enteredMonth >= currentMonth)) &&
        enteredMonth >= 1 &&
        enteredMonth <= 12
      ) {
        return true;
      }
    }

    return false;
  };
  const validateFields = () => {
    let error = false;
    if (expiryDate === "") {
      setValidateError((prev) => ({
        ...prev,
        expiryDate: t("YOUR_CARD_EXPIRATION"),
      }));
    } else if (expiryDate?.length < 7) {
      setValidateError((prev) => ({
        ...prev,
        expiryDate: t("VALID_EXPIRATION_DATE"),
      }));
      error = true;
    } else if (!isValidExpiryDate(expiryDate)) {
      setValidateError((prev) => ({
        ...prev,
        expiryDate: t("YOUR_CARD_EXPIRATION_PAST"),
      }));
      error = true;
    }
    const ValiedForzip = ["GB", "US", "CA"];
    const isValidForzip = ValiedForzip.includes(checkoutCountry);
    if (isValidForzip) {
      if (zipCode === "") {
        setValidateError((prev) => ({
          ...prev,
          zipCode: t("YOUR_POSTAL_CODE_INCOMPLETE"),
        }));
        error = true;
      }
      // Zip Code validation
      const zipRegex = getRegexByCountryCode(checkoutCountry);
      if (!zipRegex.test(zipCode)) {
        setValidateError((prev) => ({
          ...prev,
          zipCode: t("YOUR_POSTAL_CODE_INCOMPLETE"),
        }));
        error = true;
      }
    }
    return error;
  };

  const handleSubmit = async (type, token) => {
    setIsLoading(true);
    let payLoad;
    if (type === "google_pay") {
      payLoad = {
        google_pay: {
          payment_data: token,
          country: country,
          city: city,
          state: state,
        },
        email: emailState,
      };
    } else if (type === "apple_pay") {
      payLoad = {
        apple_pay: {
          payment_data: token,
          country: country,
          city: city,
          state: state,
        },
        email: emailState,
      };
    }

    var browser_size = "02";
    var acceptHeader = "text/html,application/xhtml+xml;q=0.9,*/*;q=0.8";
    let browser_info = Spreedly.ThreeDS.serialize(browser_size, acceptHeader);
    let cardData = {
      token: token,
      type: "payment-method-create/fulfilled",
    };
    try {
      const response =
        type === "card" ? cardData : await callGetPaymentMethod(payLoad);
      if (response.type === "payment-method-create/fulfilled") {
        const data = {
          currency: cur.substr(0, 3),
          amount: window.location.pathname.includes("subscription")
            ? Number(subscriptionProductPrice?.sent_amount)
            : TrialCurr.trial
            ? Number(TrialCurr?.trial.first_sent_amount)
            : Number(TrialCurr?.sent_amount),
          token:
            type === "card"
              ? token
              : response.payload.data.transaction.payment_method.token,
          email: emailState,
          type: type,
          browser_info,
          country: sendDetailsData.data.country_code,
          user_id: user_id,
        };

        const secretResponse = await callNewPaymentIntent(data);
        if (secretResponse.type === "payment-intent-create/fulfilled") {
          let result = secretResponse.payload.data;
          const payment_method = result.payment_method;
          const apiPayload = {
            email: emailState,
            amount: window.location.pathname.includes("subscription")
              ? subscriptionProductPrice?.sent_amount
              : TrialCurr?.sent_amount,
            payment_method: payment_method,
            price: subscriptionProductPrice.price_id,
            currency: cur.substr(0, 3),
            subscription_id: secretResponse.payload.subscription_id,
            plan: window.location.pathname.includes("subscription")
              ? "standard"
              : "trail",
            user_id: user_id,
          };
          let isSuccess = false;
          if (result.spreedly.state === "pending") {
            await stripe
              .confirmCardPayment(result.spreedly.client_secret)
              .then(function (result) {
                if (result.error) {
                  setMessage({ ...message, creditCard: result.error.message });
                } else {
                  isSuccess = true;
                }
              });
          }
          if (TrialCurr?.trial?.second_sent_amount) {
            apiPayload.second_trial_amount = TrialCurr.trial.second_sent_amount;
          }
          if (result.spreedly.state === "succeeded" || isSuccess) {
            const subscriptionResult = await callsubscriptionCreate(apiPayload);
            if (subscriptionResult.type === "subscriptionCreate/fulfilled") {
              subscriptionResult.payload.message &&
                Notify("success", t("NOTIFY_STANDARD_SUBSCRIPTION"), "");
              if (
                window.location.pathname.includes("track") ||
                window.location.pathname.includes("trace")
              ) {
                if (
                  paramas &&
                  typeof paramas === "object" &&
                  !Object.entries(paramas)
                    .map(([key, value]) => {
                      if (key === "off") {
                        return "off";
                      }
                    })
                    .includes("off")
                ) {
                  cookie.set("banned", "true");
                  const data = {
                    ip_address: ip,
                    status: false,
                  };
                  await callSettingBanIp(data);
                }
              }
              if (gclid && gclid.length > 0 && gclid[0].key && gclid[0].value) {
                const gclidData = {
                  email: data.email?.toLowerCase(),
                  key: gclid[0].key,
                  value: gclid[0].value,
                  signup: "on",
                  ip_address: ip,
                };
                callgclidData(gclidData);
              }
              cookie.set("token", subscriptionResult.payload.data.token, {
                path: "/",
              });
              cookie.set("role", subscriptionResult.payload.data.role, {
                path: "/",
              });
              cookie.set("email", subscriptionResult.payload.data.email);
              if (senderNumberCountry && senderNumber) {
                navigate(
                  `/${lang}/dash?D=+${senderNumberCountry.replaceAll(
                    " ",
                    ""
                  )}&n=${senderNumber}`
                );
              } else {
                navigate(`/${lang}/dash`);
              }
            } else {
              if (subscriptionResult.error) {
                if (type === "card") {
                  setMessage({
                    ...message,
                    creditCard: subscriptionResult.error.message,
                  });
                } else if (type === "google_pay") {
                  setMessage({
                    ...message,
                    googlePay: subscriptionResult.error.message,
                  });
                } else if (type === "apple_pay") {
                  setMessage({
                    ...message,
                    applePay: subscriptionResult.error.message,
                  });
                }
              }
            }
          }
          let currencyUppercase = cur.substr(0, 3).toUpperCase();
          gtag("event", "purchase", {
            transaction_id: result.id,
            user_id: useId,
            page_version: pageVersion,
            purchase_amount: window.location.pathname.includes("subscription")
              ? subscriptionProductPrice?.amount
              : TrialCurr?.amount,
            currency: currencyUppercase,
            email: data.email?.toLowerCase(),
            payment_method: payment_method,
            payment_details: [result],
            postal_code: postalCode,
            country: country,
          });
          // Add Zaraz for trail purchase
          if (window.zaraz) {
            window.zaraz.track("purchase", {
              email: data.email?.toLowerCase(),
              user_id: useId,
              value: TrialCurr?.amount,
              currency_code: currencyUppercase,
              transaction_id: result.id,
              postal_code: postalCode,
              country: country,
            });
          }
          gtag("event", "conversion", {
            send_to: "AW-11387801114/zsDDCPmr7O8YEJqckLYq",
            value: subscriptionProductPrice.amount,
            currency: currencyUppercase,
            transaction_id: result.id,
            email: data.email?.toLowerCase(),
            postal_code: postalCode,
            country: country,
          });
        } else {
          if (secretResponse.error) {
            if (type === "card") {
              setMessage({
                ...message,
                creditCard: secretResponse.error.message,
              });
            } else if (type === "google_pay") {
              setMessage({
                ...message,
                googlePay: secretResponse.error.message,
              });
            } else if (type === "apple_pay") {
              setMessage({
                ...message,
                applePay: secretResponse.error.message,
              });
            }
          }
        }
      } else {
        if (response.error) {
          if (type === "card") {
            setMessage({ ...message, creditCard: response.error.message });
          } else if (type === "google_pay") {
            setMessage({ ...message, googlePay: response.error.message });
          } else if (type === "apple_pay") {
            setMessage({ ...message, applePay: response.error.message });
          }
        }
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };
  const handleChangeCountry = (e) => {
    setValidateError((prev) => ({
      ...prev,
      zipCode: "",
    }));
    setCountry(e.target.value);
  };
  function cc_expires_format(string) {
    return string
      .replace(
        /[^0-9]/g,
        "" // To allow only numbers
      )
      .replace(
        /^([2-9])$/,
        "0$1" // To handle 3 > 03
      )
      .replace(
        /^(1{1})([3-9]{1})$/,
        "0$1 / $2" // 13 > 01 / 3
      )
      .replace(
        /^0{1,}/,
        "0" // To handle 00 > 0
      )
      .replace(
        /^([0-1]{1}[0-9]{1})([0-9]{1,2}).*/,
        "$1 / $2" // To handle 113 > 11 / 3
      );
  }
  const handleExpiryChange = (event) => {
    setValidateError((prev) => ({
      ...prev,
      expiryDate: "",
    }));
    const formated = cc_expires_format(event.target.value);
    setExpiryDate(formated);
    if (isValidExpiryDate(formated)) {
      setCardValidateError((prev) => ({
        ...prev,
        yearError: null,
      }));
    }
  };
  const getPlaceholderByCountryCode = (countryCode) => {
    switch (countryCode) {
      case "CA":
        return "M5T 1T4";
      case "GB":
        return "WS11 1DB";
      case "US":
        return "12345";
      default:
        return "";
    }
  };
  const getRegexByCountryCode = (countryCode) => {
    switch (countryCode) {
      case "CA":
        return /^([ABCEGHJKLMNPRSTVXY]\d[ABCEGHJKLMNPRSTVWXYZ]) ?(\d[ABCEGHJKLMNPRSTVWXYZ]\d)$/;
      case "GB":
        return /^([A-Za-z]{2}[\d]{1,2}[A-Za-z]?)[\s]+([\d][A-Za-z]{2})$/;
      case "US":
        return /^\d{5}(?:[-\s]\d{4})?$/;
      default:
        return /.*/;
    }
  };

  return (
    <div className="body-modal mb-top-wrapper">
      <div className="payment-details">
        <div className="df-details">
          <div className="left-payment">
            <p>{t("SIGNUP_PAYMENT_TITLE")}</p>
          </div>
          <div className="right-payment pay">
            <p>
              {subscription !== "standard"
                ? paymentPrice?.symbol + paymentPrice?.amount
                : paymentPrice?.symbol + subscriptionProductPrice?.amount}
            </p>
          </div>
        </div>
        <div className="right-payment pay">
          {!(page === "track" || page === "trace") && (
            <span>
              {page === "track" || page === "trace"
                ? t("TRIAL_TIME.B")
                : t("TRIAL_TIME", {
                    amount:
                      subscriptionProductPrice?.symbol +
                      subscriptionProductPrice?.amount,
                  })}
            </span>
          )}
        </div>
        {(page === "track" || page === "trace") && (
          <div className="time-span">
            <span>
              {page === "track" || page === "trace"
                ? t("TRIAL_TIME.B")
                : t("TRIAL_TIME", {
                    amount:
                      subscriptionProductPrice?.symbol +
                      subscriptionProductPrice?.amount,
                  })}
            </span>
          </div>
        )}
      </div>

      <div style={{ marginTop: 10 }}>
        <div className="payment-option-tabs">
          <div className="tabs">
            {(isApplePayAvailable || isGooglePayAvailable) && (
              <div
                className={`${
                  method === "card" ? "active-tab" : ""
                } card-payment-tab`}
                onClick={() => {
                  setMethod("card");
                }}
              >
                <div className="box">
                  <div className="df-card-box">
                    <MdOutlineCreditCard />
                    <p>{t("PAY_WITH_CARD")}</p>
                  </div>
                </div>
                {method === "card" && (
                  <div className="rightIcon-div">
                    <img src={RightIcon} alt="" />
                  </div>
                )}
              </div>
            )}
            {!isApplePayAvailable && isGooglePayAvailable && (
              <div
                className={`${
                  method === "googlePay" ? "active-tab" : ""
                } gpay-payment-tab`}
                onClick={() => {
                  setMethod("googlePay");
                }}
              >
                <div className="box">
                  <div className="df-card-box">
                    <img src={googlePayIcon} alt="" />
                    <p>Google Pay</p>
                  </div>
                </div>
                {method === "googlePay" && (
                  <div className="rightIcon-div">
                    <img src={RightIcon} alt="" />
                  </div>
                )}
              </div>
            )}

            {isApplePayAvailable && (
              <div
                className={`${
                  method === "applePay" ? "active-tab" : ""
                } applePay-payment-tab`}
                onClick={() => {
                  setMethod("applePay");
                }}
              >
                <div className="box">
                  <div className="df-card-box">
                    <img src={applePayIcon} alt="" />
                    <p>Apple Pay</p>
                  </div>
                </div>
                {method === "applePay" && (
                  <div className="rightIcon-div">
                    <img src={RightIcon} alt="" />
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        {method === "card" && (
          <Form>
            <div className="checkout-form-container">
              <input
                type="hidden"
                name="payment_method_token"
                id="payment_method_token"
                value=""
              />
              <>
                <label>{t("CARD_NUMBER_LABEL")}</label>
              </>
              <div
                id="spreedly-number"
                class="spf-field-group spf-number spf-field-cc"
              >
                 <img
                  src={cardIcons}
                  className={
                    renderRTLClass
                      ? "suffix-card-icons-rtl"
                      : "suffix-card-icons"
                  }
                  alt=""
                />
              </div>
              {cardValidateError.numberError && (
                <p className="common-error">{cardValidateError.numberError}</p>
              )}
              <div style={{ display: "flex", gap: 10, marginTop: 5 }}>
                <div style={{ width: "50%" }}>
                  <label>{t("SIGNUP_EXPIRATION_LABEL")}</label>
                  <Input
                    className={`${
                      validateError.expiryDate || cardValidateError.yearError
                        ? "errorFelid"
                        : ""
                    } "payment-inputs" ${
                      renderRTLClass ? "checkout-payment-inputs" : ""
                    } `}
                    type="text"
                    name="expiry"
                    inputmode="numeric"
                    value={expiryDate}
                    onChange={handleExpiryChange}
                    placeholder="MM / YY"
                    maxLength="7"
                    style={{
                      height: 40,
                      width: "100%",
                      border: "1px solid",
                      borderColor: "#e6e6e6",
                      borderRadius: 5,
                      paddingLeft: 10,
                    }}
                  />
                  {!cardValidateError.yearError && validateError.expiryDate && (
                    <p style={{ color: "red", margin: 0, lineHeight: 1.2 }}>
                      {validateError.expiryDate}
                    </p>
                  )}
                  {cardValidateError.yearError && (
                    <p className="common-error">
                      <p style={{ color: "red", margin: 0, lineHeight: 1.2 }}>
                        {cardValidateError.yearError}
                      </p>
                    </p>
                  )}
                </div>
                <div style={{ width: "50%" }}>
                  <label>{t("SIGNUP_CVC_LABEL")}</label>
                  <div
                    id="spreedly-cvv"
                    className="spf-field-group spf-verification_value spf-field-cc"
                  ></div>
                  {(validateError.cvv || (!isValidCVV && isFormSubmitted)) && (
                    <p style={{ color: "red", margin: 0, lineHeight: 1.2 }}>
                      {validateError.cvv || t("CVC_INCOMPLETE")}
                    </p>
                  )}
                </div>
              </div>
              <div style={{ display: "flex", gap: 10, marginTop: 12 }}>
                <div style={{ width: isValidZip ? "50%" : "100%" }}>
                  <label>{t("COUNTRY_LABEL")}</label>
                  <select
                    style={{
                      height: "40px",
                      width: "100%",
                      borderRadius: "5px",
                      border: "1px solid rgb(230, 230, 230)",
                      paddingLeft: "10px",
                    }}
                    name="country"
                    onChange={handleChangeCountry}
                    defaultValue={country_code}
                    value={checkoutCountry}
                  >
                    {countryOptions?.map((item, index) => {
                      return (
                        <option key={index} value={item.value}>
                          {item.label}
                        </option>
                      );
                    })}
                  </select>
                </div>
                {isValidZip && (
                  <div style={{ width: "50%" }}>
                    <label>{label}</label>
                    <Input
                      className={`${
                        validateError.zipCode ? "errorFelid" : ""
                      } "payment-inputs"`}
                      placeholder={getPlaceholderByCountryCode(checkoutCountry)}
                      name="zipcode"
                      onChange={handleChangeZip}
                      value={zipCode}
                      style={{
                        height: "40px",
                        width: "100%",
                        border: "1px solid rgb(230, 230, 230)",
                        borderRadius: "5px",
                        paddingLeft: "10px",
                      }}
                    />
                    {validateError.zipCode && (
                      <p style={{ color: "red", margin: 0, lineHeight: 1.2 }}>
                        {validateError.zipCode}
                      </p>
                    )}
                  </div>
                )}
              </div>
              <script
                id="spreedly-iframe"
                data-number-id="spreedly-number"
                data-cvv-id="spreedly-cvv"
              ></script>
              {message.creditCard && (
                <div id="payment-message" style={{ color: "red" }}>
                  {message.creditCard}
                </div>
              )}
            </div>
            <button
              onClick={(event) => submitPaymentForm(event)}
              disabled={isLoading || !stripe || intentLoading}
              id="payment-form"
              className="hl_cta_wrap mt-4"
              type="submit"
              style={{ width: "100%" }}
            >
              {t("SIGNUP_SUBMIT")}
            </button>
          </Form>
        )}
        {/* Google pay start */}
        {method === "googlePay" &&
          !isApplePayAvailable &&
          isGooglePayAvailable && (
            <>
              <div className="google-pay-content">
                <div className="top-content">
                  <div className="icon">
                    <img src={googlePayIcon} alt="" />
                  </div>
                  <p>{t("GOOGLE_PAY_SELECTED")}</p>
                </div>
                <div className="bottom-content">
                  <div className="df-icon-secure">
                    <div className="icon">
                      <img src={paymentAuthIcon} alt="" />
                    </div>
                    <div className="content">
                      <p>{t("WALLET_NOTE")}</p>
                    </div>
                  </div>
                </div>
              </div>
              {message.googlePay && (
                <div id="payment-message" style={{ color: "red" }}>
                  {message.googlePay}
                </div>
              )}
              <div className="gpay-buttons">
                <GooglePay
                  handleSubmit={handleSubmit}
                  setIsLoading={setIsLoading}
                />
              </div>
            </>
          )}
        {/* Apple pay start */}
        {method === "applePay" && isApplePayAvailable && (
          <>
            <div className="google-pay-content">
              <div className="top-content">
                <div className="icon">
                  <img src={applePayIcon} alt="" />
                </div>
                <p>{t("APPLE_PAY_SELECTED")}</p>
              </div>
              <div className="bottom-content">
                <div className="df-icon-secure">
                  <div className="icon">
                    <img src={paymentAuthIcon} alt="" />
                  </div>
                  <div className="content">
                    <p>{t("WALLET_NOTE")}</p>
                  </div>
                </div>
              </div>
            </div>
            {message.applePay && (
              <div id="payment-message" style={{ color: "red" }}>
                {message.applePay}{" "}
              </div>
            )}
            <div className="gpay-buttons">
              <div className="applePayButton">
                <ApplePayPage
                  price={
                    window.location.pathname.includes("subscription")
                      ? Number(subscriptionProductPrice?.amount)
                      : TrialCurr.amount
                  }
                  currency={cur}
                  country={country_code}
                  handleSubmit={handleSubmit}
                  setIsLoading={setIsLoading}
                  isLoading={isLoading}
                />
              </div>
            </div>
          </>
        )}

        {!(page === "track" || page === "trace") && (
          <span id="sign-up-term">
            {t("CHECKOUT_NOTE", {
              trialPrice: paymentPrice?.symbol + paymentPrice?.amount,
              subPrice:
                subscriptionProductPrice?.symbol +
                subscriptionProductPrice?.amount,
            })}
          </span>
        )}
      </div>
    </div>
  );
};

export default CheckoutComponent;
