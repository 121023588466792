import { Modal, Spin } from "antd";
import React, { useState } from "react";
import "./reactivePaymentModal.scss";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
} from "@stripe/react-stripe-js";
import Notify from "../../../common/Notify/notify";
import { Cookies } from "react-cookie";
import { useTranslation } from "react-i18next";

const ReactivePaymentModalComponent = ({
  useElements,
  useStripe,
  callUpdateBillingMethod,
  handlePaymentUpdate,
  productList,
}) => {
  const cookies = new Cookies();
  const cur = cookies.get("currency");
  const { t } = useTranslation();
  const [stripeError, setStripeError] = useState("");
  const [isLoading, setIsLoading] = useState("");
  const stripe = useStripe();
  const elements = useElements();
  const elementStyles = {
    base: {
      padding: "50px",
      height: "20px",
      fontSize: "16px",
      color: "#424770",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
  };
  var elementClasses = {
    focus: "focus",
    empty: "empty",
    invalid: "invalid",
  };
  const cardOptions = {
    style: elementStyles,
    classes: elementClasses,
  };

  const cardExpiryOptions = {
    style: elementStyles,
    placeholder: "MM/YY",
  };

  const cardCvcOptions = {
    style: elementStyles,
    placeholder: "CVV/CVC",
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    if (!stripe || !elements) {
      return;
    }

    const cardNumberElement = elements.getElement(CardNumberElement);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardNumberElement,
    });

    if (error) {
      setStripeError(error.message);
      setIsLoading(false);
    } else {
      // send the paymentMethod.id to server to create a payment
      const data = {
        payment_method: paymentMethod.id,
      };
      const result = await callUpdateBillingMethod(data);
      if (result.type === "updateBillingMethod/fulfilled") {
        if (result.payload.data.action_required) {
          const { error, paymentIntent } = await stripe.confirmCardPayment(
            result.payload.data.client_secret,
            {
              payment_method: {
                card: cardNumberElement,
              },
            }
          );
          if (!error) {
            Notify("success", result.payload.message, "");
            handlePaymentUpdate();
            setIsLoading(false);
            window.scrollTo({
              top: 0,
              behavior: "smooth",
            });
          } else {
            setIsLoading(false);
            setStripeError(error.message);
          }
        } else {
          Notify("success", result.payload.message, "");
          handlePaymentUpdate();
          setIsLoading(false);
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        }
      } else {
        Notify("error", result.error.message, "");
        setIsLoading(false);
      }
    }
  };
  const subscriptionProduct = productList?.data.find(
    (item) => item.title === "standard"
  );
  const subscriptionProductPrice = subscriptionProduct?.currency_options.find(
    (item) => item.parameter === cur
  );
  return (
    <Spin spinning={isLoading}>
      <div className="mpo_modal_wrapper">
        <div className="mpo_modal_content">
          <h4>{t("UPDATE_PAYMENT_METHOD")}</h4>
          <p>{t("UPDATE_PAYMENT_METHOD_DESC")}</p>
        </div>
        <div className="mpo_modal_form">
          <div className="transaction__popup">
            <div className="main_container">
              <div className="transaction_bg_wrap">
                <div className="transaction_form">
                  <form action="/">
                    <div className="form-container-payment">
                      <div className="field-container">
                        <label htmlFor="cardnumber">{t("CARD_NUMBER")}</label>
                        <div className="cq_panel__components main-phone-card">
                          <CardNumberElement options={cardOptions} />
                        </div>
                      </div>
                      <div className="field-container">
                        <label htmlFor="expirationdate">{t("CARD_EXP")}</label>
                        <div className="cq_panel__components main-phone-date">
                          <CardExpiryElement options={cardExpiryOptions} />
                        </div>
                      </div>
                      <div className="field-container">
                        {/* <label htmlFor="securitycode">dsfsd</label> */}
                        <div className="cq_panel__components main-phone-cvv">
                          <CardCvcElement options={cardCvcOptions} />
                        </div>
                      </div>
                    </div>
                    {stripeError && (
                      <p className="stripe_error">{stripeError}</p>
                    )}
                    <div className="submit_button">
                      <button
                        className="payment__Submit"
                        type="button"
                        onClick={handleSubmit}
                      >
                        {t("REACTIVATE")}
                      </button>
                    </div>
                    <div className="subscription_content_wrap">
                      <p>
                        {t("UPDATE_PAYMENT_METHOD_CHARGE_DESC", {
                          standard_price:
                            subscriptionProductPrice?.symbol +
                            subscriptionProductPrice?.amount,
                        })}
                      </p>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Spin>
  );
};

export default ReactivePaymentModalComponent;
