import React from "react";
import FAQComponent from "./faq";
import { connect } from "react-redux";

const FAQ = (props) => {
  const { productList, productListSingle } = props;
  return <FAQComponent productList={productListSingle} />;
};
const mapStateToProps = (state) => {
  return {
    productList: state.getProductList.productListData,
    productListSingle: state.getProductListSingle.productListSingleData,
  };
};

export default connect(mapStateToProps, null)(FAQ);
