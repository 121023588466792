import React, { useEffect, useState } from "react";
import EmailSender from "./EmailSender";
import { connect } from "react-redux";
import { getLanguages } from "../../redux/slice/getLangugueSlice";
import { emailSender } from "../../redux/slice/emailSenderSlice";

const EmailSenderPage = (props) => {
  const { callEmailSender, callLanguages } = props;
  const [data, setData] = useState([]);
  const fetchData = async (page = 1) => {
    const result = await callLanguages(page);
    if (result.type === "adminUsers/fulfilled") {
      setData(result.payload.data);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  return <EmailSender callEmailSender={callEmailSender} callLanguages={data} />;
};

const mapDispatchToProps = (dispatch) => {
  return {
    callEmailSender: (data) => dispatch(emailSender(data)),
    callLanguages: () => dispatch(getLanguages()),
  };
};

export default connect(null, mapDispatchToProps)(EmailSenderPage);
