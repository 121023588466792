import React from "react";
import UnsubscribeModalComponent from "./unsubscribeModal";

const UnsubscribeModal = ({
  unsubscribeModalOpen,
  handleCloseUnsubscribe,
  handleUnsubscribe,
  successPopup,
  unsubscribeResult,
}) => {
  return (
    <UnsubscribeModalComponent
      openModal={unsubscribeModalOpen}
      onCancel={handleCloseUnsubscribe}
      handleUnsubscribe={handleUnsubscribe}
      successPopup={successPopup}
      unsubscribeResult={unsubscribeResult}
    />
  );
};

export default UnsubscribeModal;
