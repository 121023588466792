import React from "react";
import { BrowserRouter as Router } from "react-router-dom";

const AppProvider = ({ children }) => {
  return (
    <React.Suspense>
      <Router>{children}</Router>
    </React.Suspense>
  );
};

export default AppProvider;
